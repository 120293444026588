import React from "react";
import theme from "theme";
import { Theme, Strong, Box, Icon, Text, Span, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Нотаріус Професіонал
			</title>
			<meta name={"description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:title"} content={"Нотаріус Професіонал"} />
			<meta property={"og:description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
		</Helmet>
		<Components.Marque />
		<Components.Header>
			<Override slot="box" />
			<Override slot="text">
				Нотаріус Професіонал
			</Override>
		</Components.Header>
		<Components.Hero>
			<Override slot="text">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Послуги
				</Strong>
			</Override>
			<Override slot="text1">
				Наші нотаріуси – це висококваліфіковані спеціалісти, які забезпечують юридичну чистоту та надійність ваших угод. Ми пропонуємо повний спектр нотаріальних послуг, включаючи посвідчення угод, оформлення спадщини, довіреностей та багато іншого. Працюючи з нами, ви отримуєте не лише професійну допомогу, але й індивідуальний підхід до кожної справи.
			</Override>
			<Override slot="text2" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdCheckCircle}
							color="--indigo"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Посвідчення угод
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Купівля-продаж нерухомості
						<br />
						<br />
						Договори дарування
						<br />
						<br />
						Шлюбні контракти
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-red"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdCheckCircle}
							color="--red"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Оформлення спадщини
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Свідоцтво про право на спадщину
						<br />
						<br />
						Оформлення заповітів
						<br />
						<br />
						Спадкові договори
						<br />
						<br />
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-green"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdCheckCircle}
							color="--green"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Довіреності
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Генеральні довіреності
						<br />
						<br />
						Спеціальні довіреності
						<br />
						<br />
						Довіреності на керування транспортними засобами
						<br />
						<br />
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-orange"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdCheckCircle}
							color="--orange"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Нотаріальне посвідчення документів
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Копії документів
						<br />
						<br />
						Заяви та заяви про підтвердження факту
						<br />
						<br />
						Підписи на документах
						<br />
						<br />
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-purple"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdCheckCircle}
							color="--purple"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Юридичні консультації
						</Span>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						З питань спадщини
						<br />
						<br />
						З питань укладання угод
						<br />
						<br />
						З інших юридичних питань
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Marque>
			<Override slot="text1" />
		</Components.Marque>
		<Components.Header />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});